<template>
    <div style="position:relative;">
        <p v-if="hintText">{{ hintText }}</p>
        <v-menu
            v-model="showMenu"
            open-on-click
            :close-on-content-click="false"
            max-width="290"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    :value="formattedDate"
                    :label='$t("fields.date.label")'
                    readonly
                    v-on="on"
                    append-icon="event"
                    :rules="isRequired ? rules : []"
                ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                @change="showMenu = false"
                :no-title="this.tinyHeight"
                :max="maxDate"
                :min="minDate"
            ></v-date-picker>
        </v-menu>
        <div class="icon-click" style="position:absolute;right:0;top:0;bottom:0;width:25px;height:50px;z-index:9999;"
             @click="showMenu = !showMenu"></div>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import format from 'date-fns/format';
import {parseISO, getTime, fromUnixTime} from 'date-fns';

export default {
    name: 'Date',
    props: {
        isRequired: {
            default: true
        },
        questionId: {},
        hintText: {
            type: String
        },
        data: {
            type: Object,
            default() {
                return {};
            },
        }
    },
    data() {
        const allowFuture = typeof this.data?.allowFuture === 'undefined' ? true : this.data?.allowFuture;
        const allowPast = typeof this.data?.allowPast === 'undefined' ? true : this.data?.allowPast;

        return {
            allowPast: allowPast,
            allowFuture: allowFuture,
            date: null, // new Date().toISOString().substring(0, 10),
            showMenu: false,
            rules: [
                v => !!v || this.$t('fields.general.rules.required')
            ]
        };
    },
    watch: {
        date: function(val, oldVal) {
            const parseDate = parseISO(val);
            const getTimeParsed = getTime(parseDate);
            this.updateAnswer(getTimeParsed / 1000);
        }
    },
    computed: {
        minDate() {
            if (this.allowPast) {
                return null;
            }
            return format(new Date(), 'yyyy-mm-dd');
        },
        maxDate() {
            if (this.allowFuture) {
                return null;
            }
            return format(new Date(), 'yyyy-mm-dd');
        },
        formattedDate() {
            const parsed = parseISO(this.date);
            return this.date ? format(parsed, 'MMMM do yyyy') : '';
        },
        ...mapState('surveys', ['currentSurveyAnswers', 'surveyMode']),
        tinyHeight() {
            return this.screen$.height < 480;
        }
    },
    methods: {
        updateAnswer(date) {
            if (!date) {
                const currentDate = new Date();
                const getTimeParsed = getTime(currentDate);
                date = getTimeParsed;
            }
            this.$emit('updateAnswer', date);
        },
        checkForAnswer() {
            if (this.currentSurveyAnswers) {
                this.currentSurveyAnswers.forEach((answer) => {
                    if (answer[this.questionId]) {
                        const date = answer[this.questionId];
                        const date2 = fromUnixTime(date);
                        const date3 = format(date2, 'yyyy-MM-dd');
                        this.date = new Date(date3).toISOString().substring(0, 10);
                    }
                });
            }
        }
    },
    created() {
        if (this.surveyMode === 'resume-survey') {
            this.checkForAnswer();
        }
    }
};
</script>
